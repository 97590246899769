import React from "react";
import { Error } from "@/components/Error";
import FlashBanner from "@/components/FlashBanner";
import MetaTags from "@/components/MetaTags";
import Section from "@/components/Section";
import Site from "@/components/Site";
import { Tracking } from "@/components/Tracking";
import { NextPage } from "next";
import Head from "next/head";
import { useRouter } from "next/router";
import { getPage } from "server/siteRepo";
import AOS from "aos";
import "aos/dist/aos.css";
import { PageProps } from "types";
import {
  headerToHeroMap,
  sectionsNavSettingsFallback,
} from "../../../components/section-fallbacks";
import { getGlobalStyle } from "../../../helpers/globalStyles";

const Page: NextPage<PageProps> = ({
  websiteId,
  page,
  plan,
  colors,
  universalData,
  universalSections,
  defaultLocale,
  pages,
  trackingCodes,
  error,
  animation,
  isUnderMaintenance,
  maintenancePages,
  theme,
}) => {
  const router = useRouter();

  React.useEffect(() => {
    AOS.init();
  }, []);
  if (router.isFallback) return null;
  if (error) {
    return (
      <Error
        errorCode={error?.errorCode}
        siteData={error?.siteData}
        pagesMeta={error?.pagesMeta}
        errorPages={error?.errorPages}
      />
    );
  }
  const currentLocale = page?.language;

  const isOldHomeHeader =
    !universalSections?.header_expanded[currentLocale]?.section_settings ||
    !!universalSections?.header_expanded[currentLocale]?.section_settings
      ?.background;

  const currentGlobalHeader = universalSections.header_expanded[currentLocale];

  const universalSectionsWithFallback = {
    ...universalSections,
    header_expanded: {
      ...universalSections.header_expanded,
      [currentLocale]: {
        ...currentGlobalHeader,
        section_settings: isOldHomeHeader
          ? {
              ...sectionsNavSettingsFallback[currentGlobalHeader.section_design]
                .section_settings,
              ...(!page.home_page &&
              sectionsNavSettingsFallback[currentGlobalHeader.section_design]
                .section_settings.style.isTransparent &&
              sectionsNavSettingsFallback[currentGlobalHeader.section_design]
                .section_settings.colors.navLinksColor === "#000000"
                ? {
                    style: {
                      ...sectionsNavSettingsFallback[
                        currentGlobalHeader.section_design
                      ].section_settings.styles,
                      isTransparent: false,
                    },
                  }
                : {}),
            }
          : currentGlobalHeader.section_settings,
      },
    },
  };

  const globalHeaderWithFallback =
    universalSectionsWithFallback?.header_expanded[currentLocale];

  const isGlobalNavbarTransparent =
    !!globalHeaderWithFallback?.section_settings?.style?.isTransparent;

  const pageSectionsWithFallback =
    isOldHomeHeader && page.home_page
      ? [
          {
            ...globalHeaderWithFallback,
            section_settings: { background: {} } as any,
            section_design:
              headerToHeroMap[globalHeaderWithFallback.section_design],
          },
          ...page.page_sections,
        ]
      : page.page_sections;

  const maintenancePage = maintenancePages.find(
    (page) => page.language === currentLocale
  );

  return (
    <>
      <MetaTags
        pageUrl={`${router?.query?.site}/${page.path}`}
        title={page.seo_title || page.name}
        description={page.seo_description || page.name}
        keywords={page.seo_keywords}
        faviconSrc={universalData.favicon}
        imageSrc={universalData.logo}
        openGraphTitle={page?.open_graph_title}
        openGraphDescription={page?.open_graph_description}
        openGraphImage={page?.open_graph_image}
      />
      <Head>
        {isUnderMaintenance && <title>{maintenancePage.name}</title>}
        <style
          dangerouslySetInnerHTML={{
            __html: theme
              ? `.siteBody {
             --theme-color-brand1: ${theme.colors.brand1};
        --theme-color-brand2: ${theme.colors.brand2};
        --theme-color-brand3: ${theme.colors.brand3};
        --theme-color-brand4: ${theme.colors.brand4};
        --theme-color-secondary1: ${theme.colors.secondary1};
        --theme-color-secondary2: ${theme.colors.secondary2};
        --theme-color-secondary3: ${theme.colors.secondary3};
        --theme-color-secondary4: ${theme.colors.secondary4};
        --theme-color-base1: ${theme.colors.base1};
        --theme-color-base2: ${theme.colors.base2};
        --theme-color-base3: ${theme.colors.base3};
        --theme-color-base4: ${theme.colors.base4};
        --theme-color-base5: ${theme.colors.base5};
        --theme-color-accent1: ${theme.colors.accent1};
        --theme-color-accent2: ${theme.colors.accent2};
        --white-background-color: white;
        --theme-transparent-color: transparent;
					}`
              : `.siteBody {
						--theme-color-primary: ${colors[0]};
            --wt-btn-primary-bg-color:${colors[0]};
						--theme-color-secondary: ${colors[1]};
						--theme-color-gradient: ${colors[3]};
					}`,
          }}
        />
        {theme && (
          <style
            dangerouslySetInnerHTML={{
              __html: getGlobalStyle(theme),
            }}
          />
        )}
      </Head>
      <Tracking codes={trackingCodes} />
      {Number(plan?.id) === 1 ? <FlashBanner /> : null}
      <Site
        websiteId={websiteId}
        page={page}
        pages={pages}
        universalSections={universalSectionsWithFallback}
        universalData={universalData}
        currentLocale={page?.language}
        defaultLocale={defaultLocale}
        animation={animation}
        isUnderMaintenance={isUnderMaintenance}
        theme={theme}
      >
        {isUnderMaintenance ? (
          <div className="maintenance-section">
            {maintenancePage.page_sections.map((section) => (
              <Section
                key={section.id}
                id={section.id}
                universal={{ ...universalData, pageName: page.name }}
                design={section.section_design}
                values={section.section_value}
                animation={theme ? theme.animation : animation}
                sectionSettings={section.section_settings}
                addExtraPaddingForGlobalNavbar={false}
              />
            ))}
          </div>
        ) : (
          pageSectionsWithFallback.map((section, index) => (
            <Section
              key={section.id}
              id={section.id}
              universal={{ ...universalData, pageName: page.name }}
              design={section.section_design}
              values={section.section_value}
              animation={theme ? theme.animation : animation}
              sectionSettings={section.section_settings}
              addExtraPaddingForGlobalNavbar={
                index === 0 && isGlobalNavbarTransparent
              }
            />
          ))
        )}
      </Site>
    </>
  );
};

export async function getServerSideProps({ params: { site, slugs } }) {
  try {
    const data = await getPage(site, slugs.join("/"));
    return {
      props: {
        websiteId: data?.site?.id,
        page: data.page,
        universalData: data.site.universalData,
        universalSections: data.site.universalSections,
        pages: data.site.pages,
        defaultLocale: data.site.defaultLocale,
        currentLocale: data.site.currentLocale,
        colors: data.site.colors || [],
        trackingCodes: data.site.trackingCodes || {},
        plan: data.site.plan,
        animation: data.site.animation,
        customCodes: data.site.customCodes || null,
        isUnderMaintenance: data.site.isUnderMaintenance,
        maintenancePages: data.site.maintenancePages || [],
        theme: data.site.theme?.theme?.content,
      },
    };
  } catch (error) {
    if (error?.errorCode === 404) {
      if (error?.isUnderMaintenance) {
        return {
          redirect: {
            destination: "/",
          },
        };
      }
      return { notFound: true };
    }

    return {
      props: {
        error: {
          name: error?.name,
          errorCode: error?.errorCode || null,
          errorPages: error?.errorPages,
          siteData: error?.siteData,
          pagesMeta: error?.pagesMeta,
        },
      },
    };
  }
}

export default Page;
